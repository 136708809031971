
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import appConfigModule from "@/store/plugins/appConfig";

import axios from "axios";
@Component({
  components: { FileUploaderDialog },
})
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "MessangerProject",
  });
  config: any = appConfigModule;

  uploaderDialogVisible: boolean = false;

  async uploadFile(event: any) {
    const url = `manage/messangerproject/${this.$route.params.id}/messages/upload/`;

    try {
      const { data } = await axios.post(url, event.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          event.onProgress(e, event.file);
        },
      });
      this.dataSource.model.messages = data.messages;
      this.sortDataSource();
    } catch (e) {
      throw e;
    }
  }
  getDateString(value) {
    let date = new Date(value);

    return `${("0" + date.getDate()).slice(-2)}.${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}.${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;

    return date;
  }
  sortDataSource() {
    this.dataSource.model.messages.sort(function (a, b) {
      if (a.priority > b.priority) {
        return 1;
      }
      if (a.priority < b.priority) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    });
  }
  async created() {
    await this.dataSource.get();
    this.sortDataSource();
  }
  async onInputWord(e, message, chunkIndex, alternativeIndex, wordIndex) {
    message.transcribtion.response.chunks[chunkIndex].alternatives[
      alternativeIndex
    ].words[wordIndex].word = e.targetInnerHtml;
    let messageDataSource: ObjectDataSource = new ObjectDataSource({
      id: message.id,
      model: message,
      config: {},
      className: "MessangerMessage",
    });

    messageDataSource.updateField({
      fieldName: "transcribtion",
      fieldValue: message.transcribtion,
    });
  }
  async onInputText(e, message, chunkIndex, alternativeIndex) {
    message.transcribtion.response.chunks[chunkIndex].alternatives[
      alternativeIndex
    ].text = e.targetInnerHtml;
    let messageDataSource: ObjectDataSource = new ObjectDataSource({
      id: message.id,
      model: message,
      config: {},
      className: "MessangerMessage",
    });

    messageDataSource.updateField({
      fieldName: "transcribtion",
      fieldValue: message.transcribtion,
    });
  }
}
